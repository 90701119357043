<template>
	<section class="[-] h100">
    <div>
      <h1>Planuri tratamente</h1>
      <ul cy="planuri">
        <li v-for="(plan, key, index) in data"
            class="first-letter">
          <div v-show="plan.length">
            <b class="capitalize" :class="{[`pt-${colors[index]}`]: true}">
              {{key.substring(0, 1)}}
            </b>
            {{ planuri[key] }}:
            <ul cy="categorie">
              <li v-for="item in plan"
                  class="pointer"
                  @click="goToPlan(item)">
                <h2>{{ item.nume }}</h2>
                <p class="detalii">
                  <span>{{ item.observatii }}</span>
                  <br>
                  <span>{{ moment(item.createdAt).format('Do MMM YYYY') }}</span>
<!--                  <br>-->
<!--                  <b>{{ item.etape.length }}t / {{ makeTratamentPrice() }}</b>-->
                </p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <footer class="section-footer w100">
      <ul class="flex">
        <li class="text-center mright20px"
            cy="section-actions"
            v-if="checkUser.isPtAdmin"
            v-for="(item, key) in footerMenu"
            :key="key">
          <router-link :to="`plan/${$route.params.id}`">
            <span>{{ item.name }}</span>
            <div>
              <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="21.5" cy="21.5" r="21.5" fill="#008ED9"/>
                <path d="M32 21H23V12C23 11.7348 22.8946 11.4804 22.7071 11.2929C22.5196 11.1054 22.2652 11 22 11C21.7348 11 21.4804 11.1054 21.2929 11.2929C21.1054 11.4804 21 11.7348 21 12V21H12C11.7348 21 11.4804 21.1054 11.2929 21.2929C11.1054 21.4804 11 21.7348 11 22C11 22.2652 11.1054 22.5196 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23H21V32C21 32.2652 21.1054 32.5196 21.2929 32.7071C21.4804 32.8946 21.7348 33 22 33C22.2652 33 22.5196 32.8946 22.7071 32.7071C22.8946 32.5196 23 32.2652 23 32V23H32C32.2652 23 32.5196 22.8946 32.7071 22.7071C32.8946 22.5196 33 22.2652 33 22C33 21.7348 32.8946 21.4804 32.7071 21.2929C32.5196 21.1054 32.2652 21 32 21Z" fill="white"/>
              </svg>
            </div>
          </router-link>
        </li>
      </ul>
    </footer>
	</section>
</template>

<script>
  import moment from 'moment';
  moment.locale('ro');
  import { ro } from 'vuejs-datepicker/dist/locale';
	export default {
		name: 'PlanuriTratamente',
    data () {
      return {
        colors: ['blue', 'orange', 'green'],
        footerMenu: [
          {
            name: 'adaugă plan tratament',
            route: '/'
          }
        ],
        ro,
        moment
      }
    },
    created () {
      const id = this.$router.currentRoute.params.id
      this.$store.dispatch('get_planuri', id)
    },
    computed: {
      planuri () {
        return this.$store.state.planTratamente.planuriTemplate
      },
      data () {
        return this.$store.getters.listaPlanuri
      },
      checkUser () {
        return this.$store.state.user
      }
    },
    methods: {
      goToPlan ({_id}) {
        this.$router.push(`plan/edit-plan/${this.$route.params.id}?plan_id=${_id}`)
      },
      makeTratamentPrice () {}
    }
  }
</script>

<style lang="less">
	.planuri-tratamente {
    display: flex;
    flex-direction: column;
    & > div {
      flex: 0 1 auto;
      overflow-y: scroll;
    }
    li.first-letter {
      margin-bottom: 20px;
      border-radius: 4px;
      padding: 4px;
      font-size: 1.6rem;
      div > b {
        color: white;
        margin-right: 12px;
        padding: 4px 3px 4px 8px;
        border-radius: 4px;
      }
    }
    ul ul li {
      width: 740px;
      margin: 14px 12px 12px 0;
      padding: 10px 24px;
      border: 1px solid #dcdcdc;
      border-radius: 10px;
      background-color: #eeeeee;
      &:hover {
        border-color: #939393;
      }
    }
    h2 {
      font-size: 1.8rem;
    }
    .detalii {
      margin-top: 4px;
      font-size: 1.6rem;
      line-height: 1.3;
    }
	}
</style>
